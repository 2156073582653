import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import gql from "graphql-tag";
export var SELLER_ORDER_LIST = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query($pageSize: Int!, $page: Int!, $status: String, $search: String) {\n    seller_order_list(\n      pageSize: $pageSize\n      page: $page\n      status: $status\n      search: $search\n    ) {\n      isSuccess\n      message\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        sellerOrderId\n        ship_by\n        statusShow\n        status\n        total\n        user {\n          username\n          full_name\n        }\n        itemsLess {\n          _id\n          mainImage\n          productName\n          url\n          amount_buy\n          amount\n          properties {\n            id\n            name\n            title\n            image\n          }\n        }\n        created_at\n      }\n    }\n  }\n"])));
export var SELLER_ORDER = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query($id: Int) {\n    seller_order(id: $id) {\n      isSuccess\n      message\n      canCancel\n      canConfirmCancel\n      data {\n        _id\n        sellerOrderId\n        ship_by\n        total\n        status\n        statusShow\n        user {\n          username\n          full_name\n        }\n        logistics {\n          logisticsName\n        }\n        address {\n          name\n          phone\n          address\n          province\n          amphoe\n          district\n          postcode\n        }\n        vendors {\n          status\n          statusShow\n          total_product\n          shipping_price\n          forwarder_price\n          file_shipping\n          fee\n          total\n          total_pay\n          remark\n          cancel_remark\n          cancel_request\n          items {\n            _id\n            mainImage\n            productName\n            url\n            amount_buy\n            amount\n            price\n            properties {\n              id\n              name\n              title\n              image\n            }\n          }\n          transports {\n            _id\n            transportId\n            transportNumber\n            status\n            statusShow\n            timeline {\n              send_to_warehouse_china\n              send_out_china\n              send_to_warehouse_th\n              payshipping\n              sent_out_to_customer\n            }\n            timeline_list {\n              name\n              date\n            }\n          }\n        }\n        created_at\n      }\n    }\n  }\n"])));
export var SELLER_ORDER_TRACKING_CREATE = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($input: TrackingInput, $orderId: ID) {\n    seller_order_tracking_create(input: $input, orderId: $orderId) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var SELLER_ORDER_TRACKING_UPDATE = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($input: TrackingInput) {\n    seller_order_tracking_update(input: $input) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var SELLER_ORDER_TRACKING_DELETE = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation($_id: ID, $orderId: ID) {\n    seller_order_tracking_delete(_id: $_id, orderId: $orderId) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var SELLER_ORDER_STATUS = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query {\n    seller_order_status {\n      isSuccess\n      message\n      records {\n        status\n        value\n      }\n    }\n  }\n"])));
export var SELLER_ORDER_CANCEL = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation($_id: ID, $remark: String) {\n    seller_order_cencel(_id: $_id, remark: $remark) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var CONFIRM_SELLER_ORDER_CANCEL = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation($_id: ID) {\n    confirm_seller_order_cencel(_id: $_id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var UPLOAD_SELLER_ORDER = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation($_id: ID, $file: String) {\n    upload_seller_order(_id: $_id, file: $file) {\n      isSuccess\n      message\n    }\n  }\n"])));